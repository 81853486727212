import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        justifyContent: 'center',
        alignItems: 'center',

    },
    root: {
        flexGrow: 1,
    },
    welcome: {
        justifyContent: 'center',
        textAlign: 'center',
    },
}));