import { Typography } from "@material-ui/core";
import React from "react";
// eslint-disable-next-line
import { Logo, WelcomeStyle, AskAgents } from "./styles";
import { Link } from 'react-scroll';
import ImageSlider from "../ImageSlider/imageSlider";
import { flexbox } from "@mui/system";

const Welcome = () => {
  return (
    <>
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          display: flexbox }}
        id="home"
      >
        <WelcomeStyle>
          <img
            src="https://i.ibb.co/kqmNtSB/cgb-letters.png"
            alt="welcome-text-logo"
          />
          <ImageSlider />
          <br />
          <Typography variant="h5" gutterBottom component="div" style={{fontWeight: '600', letterSpacing: '1px'}}>
            Our location:
            <Typography variant="h6" gutterBottom component="div">
              252 Atlantic Ave, Pittsburg, CA 94565
            </Typography>
            <br />
            <Typography variant="h5" gutterBottom component="div" style={{fontWeight: '600', letterSpacing: '1px'}}>
            Hours:
            </Typography>
            <Typography variant="h6" gutterBottom component="div">
              Monday - Saturday: 9:00am - 5:00pm <br/>Sunday: closed
            </Typography>
          </Typography>
          <br/>
          <Typography variant="h5" gutterBottom component="div" style={{fontWeight: '600', letterSpacing: '1px'}}>
            Services Offered:
            <br />
            <Typography variant="h6" gutterBottom component="div"style={{fontWeight: '500', letterSpacing: '1px'}}>
            
              Parcels <br/>
              Documents <br/>
              AIR & SEA Cargo<br/>
              Money Remittances<br/>
              Domestic Airline Tickets<br/>
              International Airline Tickets<br/>
              
            </Typography>
            <br/>
            
            <Typography variant="h6" gutterBottom component="div" style={{fontWeight: '600', letterSpacing: '1px'}}>
            
            <Link to="contact" smooth={true} duration={1000} style={{cursor: 'pointer'}}>Kindly ask our Agents for more details.</Link>
            
            </Typography>
            
          </Typography>
        </WelcomeStyle>
        
      </div>
    </>
  );
};
export default Welcome;
