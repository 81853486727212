import React from 'react'
import { Nav, NavLink, Bars, NavMenu, NavLogo, MobileIcon } from './styles';

const Navbar = ({ toggle }) => {
    return(
        <>
        <Nav>
            <NavLogo to="home" smooth={true} duration={1000}>
            <img src="https://i.ibb.co/Mg2rXhw/CGBSC-alt-500.png" alt="CGB-Shipping-Cart-transparent-logo" border="0" height="60"/>
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <Bars />
            </MobileIcon>
            <NavMenu>
                <NavLink to="home" smooth={true} duration={1000}>Home</NavLink>
                <NavLink to="form" smooth={true} duration={1000}>Request Pickup</NavLink>
                <NavLink to="contact" smooth={true} duration={1000}>Contact</NavLink>
            </NavMenu>
        </Nav>
        </>
    );
};

export default Navbar;