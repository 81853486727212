import styled from 'styled-components'

export const Logo = styled.div `
    z-index: 10;
    img{
        max-width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
    }
    
`
export const WelcomeStyle = styled.div `
    display: 'flex'; 
    flexDirection: 'column'; 
    justifyContent: 'center'; 
    alignItems: 'center';
    textAlign: 'center';
    fontFamily: 'Sora', sans-serif;
    img {
        max-width: 100%;
    }
`