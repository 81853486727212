// This component is used to render the Google Forms iframe
import React from 'react';

const GForm = () => {
  return (
    <div id='form'>
      <iframe title="google-forms" src="https://docs.google.com/forms/d/e/1FAIpQLSfNuDjXa1V8LUkGO9mirFhBc0NfkgVkE-Lxu_MsciRzyiVa4w/viewform?embedded=true" allowfullscreen="" frameborder="0" width="100%" height="1700">Loading…</iframe>
    </div>
  )
};

export default GForm;