import React from 'react'
import {SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarLink, SidebarMenu} from './SidebarElements'


const Sidebar = ({ isOpen, toggle }) => {
    return ( 
        <SidebarContainer isOpen={ isOpen } onClick={ toggle }>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="home" smooth={true} duration={1000}>
                        Home
                    </SidebarLink>
                    <SidebarLink to="form" smooth={true} duration={1000}>
                        Request Pickup
                    </SidebarLink>
                    <SidebarLink to="contact" smooth={true} duration={1000}>
                        Contact
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar