import { Typography } from "@material-ui/core";
import React from "react";
const Contact = () => {
  return (
    <>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center", marginTop: "200px", marginBottom: "200px"
        }} id="contact"
      >
        
        <img src="https://i.ibb.co/Mg2rXhw/CGBSC-alt-500.png" alt="logo" style={{height:'260px'}}/>
        <br/>
        <br/>
        <Typography variant="h4" gutterBottom style={{fontWeight: '600', letterSpacing: '1px'}}>
            Contact Us
          </Typography>
        <Typography variant="h6" gutterBottom style={{fontWeight: '600', letterSpacing: '1px'}}>
            For inquiries
            <br/>
            Phone (USA): +1 (925) 477-6718
            <br/>
            Email: <a href="mailto:cgbshippingcart@gmail.com">cgbshippingcart@gmail.com</a>
          </Typography>
      </div>
    </>
  );
};

export default Contact;
