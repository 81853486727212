import React from 'react'
import Welcome from '../Welcome/welcome';
import useStyles from '../styles';
import Contact from '../Contact/contact';
import GForm from '../Form/gForm';

const Main = () => {
const classes = useStyles();

  return (
    <main className={classes.content}>
        <Welcome/>
        <GForm />
        <Contact/>
    </main>
  )
}
export default Main;