import React from "react";
const ImageSlider = () => {
  return (
    <>
      <div className="slider-container">
        <figure>
          <img src="https://i.ibb.co/Rpkf2m6/Slider-Image-1.png" alt="tropical-scenery-0" style={{padding: '10px 10px', borderRadius: '30px', width: '400px', height: '300px'}}/>
          <img src="https://i.ibb.co/TgF2QrF/Slider-Image-2.png" alt="tropical-scenery-1" style={{padding: '10px 10px', borderRadius: '30px', width: '400px', height: '300px'}}/>
        </figure>
      </div>
    </>
  );
};
export default ImageSlider;
